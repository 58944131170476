import api from "../api.service";

class PhysicalPersonEducationService {
  get PATH() {
    return "physicalperson-educationlevel";
  }

  async getChoices() {
    const res = await api.options(`${this.PATH}/`);
    return res.data.actions.POST.level.choices;
  }

  async getAll(personID, { perPage, page, sortBy = null, search = null, sortDesc = null }) {
    const offset = (page - 1) * perPage;
    const params = { persona: personID, limit: perPage, offset, search }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    return res.data;
  }

  async getOne(education) {
    const res = await api.get(`${this.PATH}/${education.id}/`);
    return res.data;
  }

  async create(education) {
    const res = await api.post(`${this.PATH}/`, education);
    return res.data;
  }

  async update(education, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, education);
    return res.data;
  }

  async delete(education) {
    await api.delete(`${this.PATH}/${education.id}`);
  }
}

export default new PhysicalPersonEducationService();
