import api from "../api.service";

class PhysicalPersonVisaService {
  get PATH() {
    return "physicalperson-visa";
  }

  async getAll(personID, { perPage, page, sortBy = null, search = null, sortDesc = null }) {
    const offset = (page - 1) * perPage;
    const params = { persona: personID, limit: perPage, offset, search }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    return res.data;
  }

  async create(visa) {
    const res = await api.post(`${this.PATH}/`, visa);
    return res.data;
  }

  async update(item, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, item);
    return res.data;
  }

  async getOne(visaID) {
    const res = await api.get(`${this.PATH}/${visaID}/`);
    return res.data;
  }

  async delete(visa) {
    await api.delete(`${this.PATH}/${visa.id}/`, visa);
  }
  
  async getVisaTypes() {
    const res = await api.options(`${this.PATH}/`);
    return res.data.actions.POST.visa_kind.choices;
  }
}

export default new PhysicalPersonVisaService();
