import api from "../api.service";

class PhysicalpersonIdentityService {
  get PATH() {
    return "/physicalperson-identitydocument";
  }

  async getAll(personID, { perPage, page, sortBy = null, search = null, sortDesc = null }) {
    const offset = (page - 1) * perPage;
    const params = { persona: personID, limit: perPage, offset, search }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });

    return res.data;
  }

  async create(identity) {
    const res = await api.post(`${this.PATH}/`, identity);
    return res.data;
  }

  async update(item, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, item);
    return res.data;
  }

  async getOne(identity) {
    const res = await api.get(`${this.PATH}/${identity.id}/`);
    return res.data;
  }

  async delete(identity) {
    await api.delete(`${this.PATH}/${identity.id}/`, identity);
  }
}

export default new PhysicalpersonIdentityService();
