import api from "../api.service";

class EmployeeContractService {
  get PATH() {
    return `employee-contract`;
  }

  async getAll(persona = null, {
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      employee__persona: persona
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });

    return res.data;
  }

  /**
   * Ritorna un contratto, in expand possiamo passare un array con i valori da chiede
   * @param {Integer} id
   * @param {Array} expand
   * @returns
   */
  async getOne(id, expand) {
    let datas = null
    if (expand) {
      datas = {
        params: expand
      }
    }

    const res = await api.get(`${this.PATH}/${id}/`, datas);

    return res.data;
  }

  async update(body, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, body);

    return res;
  }

  async delete(id) {
    const res = await api.delete(`${this.PATH}/${id}/`);

    return res;
  }
}

export default new EmployeeContractService();