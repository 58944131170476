import api from "../api.service";

class PhysicalPersonResidencePermitService {
  get PATH() {
    return "physicalperson-residencepermit";
  }

  async getOptions() {
    const res = await api.options(`${this.PATH}/`);
    return res.data;
  }

  async getAll(personID, { perPage, page, sortBy = null, search = null, sortDesc = null }) {
    const offset = (page - 1) * perPage;
    const params=  { persona: personID, limit: perPage, offset, search }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    return res.data;
  }

  async create(permit) {
    const res = await api.post(`${this.PATH}/`, permit);
    return res.data;
  }

  async update(item, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, item);
    return res.data;
  }

  async getOne(permitID) {
    const res = await api.get(`${this.PATH}/${permitID}/`);
    return res.data;
  }

  async delete(permit) {
    await api.delete(`${this.PATH}/${permit.id}/`, permit);
  }
}

export default new PhysicalPersonResidencePermitService();
