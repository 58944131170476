import api from "../api.service";

class CompanyInsuranceCertificate {
  async getAllByCompany(companyID) {
    const res = await this.getAllByCompanyPaginated(companyID, 1000, 0);
    return res.results;
  }

  async getAllByCompanyPaginated(companyID, { perPage, page, sortBy = null, search = null, sortDesc = null }) {
    const offset = (page - 1) * perPage;
    const params = { limit: perPage, offset, search, company: companyID }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get("company-insurancecertificate/", {
      params,
    });
    return res.data;
  }

  async getOne(id) {
    const res = await api.get(`company-insurancecertificate/${id}/`);
    return res.data;
  }

  async create(insurance) {
    const res = await api.post("company-insurancecertificate/", insurance);
    return res.data;
  }

  async update(insurance, id) {
    const res = await api.patch(`company-insurancecertificate/${id}/`, insurance);
    return res.data;
  }

  async delete(id) {
    await api.delete(`company-insurancecertificate/${id}`);
  }
}

export default new CompanyInsuranceCertificate();
