import api from "../api.service";
// import addressService from "../address.service";

export default {
  get PATH() {
    return "company-registrationcertificate";
  },

  async getAllByCompany(companyID) {
    const res = await this.getAllByCompanyPaginated(companyID, 1000, 0);
    return res.results;
  },

  async getAllByCompanyPaginated(companyID, {
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      company: companyID
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    return res.data;
  },

  async getOne(id) {
    const res = await api.get(`${this.PATH}/${id}/`);
    return res.data;
  },

  async create(regCertificate) {
    const res = await api.post(`${this.PATH}/`, regCertificate);
    return res.data;
  },

  async update(regCertificate, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, regCertificate);
    return res.data;
  },

  async delete(id) {
    await api.delete(`${this.PATH}/${id}`);
  },
};