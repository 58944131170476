import api from "../api.service";

class PhysicalPersonPassportService {
  get PATH() {
    return "physicalperson-passport";
  }

  async getAll(personID, { perPage, page, sortBy = null, search = null, sortDesc = null }) {
    const offset = (page - 1) * perPage;
    const params = { persona: personID, limit: perPage, offset, search }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    return res.data;
  }

  async create(passport) {
    const res = await api.post(`${this.PATH}/`, passport);
    return res.data;
  }

  async update(item, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, item);
    return res.data;
  }

  async getOne(passportID) {
    const res = await api.get(`${this.PATH}/${passportID}/`);
    return res.data;
  }

  async delete(passport) {
    await api.delete(`${this.PATH}/${passport.id}/`, passport);
  }
}

export default new PhysicalPersonPassportService();
